import React from "react";
import Pagebanner from "../Components/Pagebanner";
import { Helmet } from "react-helmet";
import Counter from "../Components/Counter";
import Nav from "../Components/Nav";
import Footer from ".././Components/Footer";

function Smm() {
  return (
    <>
      <Helmet>
        <title>Expert Social Media Marketing Solutions in the US</title>
        <meta
          name="description"
          content="Why settle for less? TeeCube offers top-tier social media marketing services in the US, ensuring sustained online success. Ready to amplify your digital presence?"
        />
        <link rel="canonical" href="https://teecubesolutionsltd.com/smm" />
      </Helmet>
      <Nav />
      <Pagebanner name={"Social Media Marketing"} />
      <div className="aproch">
        <div className="approchdetails">
          <h3>
            Best And Affordable Social Media Marketing Services By Leading
            Social Media Marketing Company
          </h3>
          <p>
            Are you searching for the best social media marketing agency to
            assist you with marketing campaigns? T Cube is here to help. We’re
            one of the best social media marketing agency in the USA providing
            effective marketing campaigns to customers all around the world with
            social media marketing services. Our marketing media strategies
            assist your brand in reaching the top spot and connecting with your
            potential targeted audience. Ranging from Facebook marketing
            services to Instagram marketing services, and even Twitter marketing
            services, we cater all your business needs. This is the reason which
            makes us the best social media marketing agency on the web. From our
            top media strategies to implementation, we are the best social media
            marketing company to cover all areas, including social media
            management, social media campaigns management, and much more.
            <ul>
              <li>Prior Social Media Marketing Analysis</li>
              <li>On-going Social Media Marketing Services</li>
              <li>Strategic SMM plans and implementation</li>
              <li>Social Media Campaign Management</li>
              <li>Social Media Reputation Management</li>
              <li>Social Consultation and Support</li>
            </ul>
          </p>
        </div>
        <div className="aprochimg">
          <img src="/seo.png" alt="..." />
        </div>
      </div>
      <div className="ser">
        <h3 className="text-center fs-1">
          We’ve Got a Proven Formula to Guarantee Results with Social Media
          Marketing Solutions
        </h3>
        <p className="text-center p-0 fs-5">
          The work isn’t done till your marketing objectives aren’t met with our
          Social Media Marketing solutions. We follow a precise and proven
          formula for success that makes us the best social media management
          agency out there in the market.
        </p>
      </div>
      <div
        class="row row-cols-1 row-cols-md-3 g-4"
        style={{ width: "80%", margin: "auto" }}
      >
        <div class="col">
          <div
            class="card"
            style={{
              padding: "20px",
            }}
          >
            <img
              src="mark.png"
              class="card-img-top"
              alt="..."
              style={{ width: "80px", margin: "auto" }}
            />
            <div class="card-body text-center">
              <h5 class="card-title">Strategy</h5>
              <p class="card-text">
                This is a longer card with supporting text below as a natural
                lead-in to additional content. This content is a little bit
                longer.
              </p>
            </div>
          </div>
        </div>
        <div class="col">
          <div
            class="card"
            style={{
              padding: "20px",
            }}
          >
            <img
              src="screen.png"
              class="card-img-top"
              alt="..."
              style={{ width: "80px", margin: "auto" }}
            />
            <div class="card-body text-center">
              <h5 class="card-title">Development</h5>
              <p class="card-text">
                This is a longer card with supporting text below as a natural
                lead-in to additional content.
              </p>
            </div>
          </div>
        </div>
        <div class="col">
          <div
            class="card"
            style={{
              padding: "20px",
            }}
          >
            <img
              src="analytics.png"
              class="card-img-top"
              alt="..."
              style={{
                width: "80px",
                display: "flex",
                justifyContent: "center",
                margin: "auto",
              }}
            />
            <div class="card-body text-center">
              <h5 class="card-title">Data Analysis</h5>
              <p class="card-text">
                This is a longer card with supporting text below as a natural
                lead-in to additional content. This content is a little bit
                longer.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Gallery --> */}
      <div
        class="row"
        style={{ width: "80%", margin: "auto", paddingTop: "50px" }}
      >
        <div class="col-lg-4 col-md-12 mb-4 mb-lg-0 sepic">
          <img
            src="/seopic/11.png"
            class="w-100 shadow-1-strong rounded mb-4"
            alt="Boat on Calm Water"
          />

          <img
            src="/seopic/12.png"
            class="w-100 shadow-1-strong rounded mb-4"
            alt="Boat on Calm Water"
          />
        </div>

        <div class="col-lg-4 mb-4 mb-lg-0 sepic">
          <img
            src="/seopic/14.png"
            class="w-100 shadow-1-strong rounded mb-4"
            alt="Mountains in the Clouds"
          />

          <img
            src="/seopic/15.png"
            class="w-100 shadow-1-strong rounded mb-4"
            alt="Boat on Calm Water"
          />
        </div>

        <div class="col-lg-4 mb-4 mb-lg-0 sepic">
          <img
            src="/seopic/15.png"
            class="w-100 shadow-1-strong rounded mb-4"
            alt="Boat on Calm Water"
          />

          <img
            src="/seopic/13.png"
            class="w-100 shadow-1-strong rounded mb-4"
            alt="Wintry Mountain Landscape"
          />
        </div>
      </div>
      {/* <!-- Gallery --> */}
      <Counter /> <Footer />
    </>
  );
}

export default Smm;
